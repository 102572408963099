<template>
  <div class="ddj-offer">
    <!-- <div class="main-Title bgff"><h2>EMU Task</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="list-filter mb10">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="offer ID:"
                ><el-input
                  v-model="filter.offerId"
                  placeholder="Please enter offer ID"
                  class="mr10"
                ></el-input
              ></el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label="Country:">
                <el-select v-model="filter.country" placeholder="Please select" class="w100">
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.country"
                    :key="item.countryCode"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="5" :xl="3">
              <el-form-item label="OS:" label-width="50px">
                <el-select v-model="filter.os" placeholder="Please select OS" class="w100">
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.os"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="5" :xl="4">
              <el-form-item label="上游名称:">
                <el-select
                  v-model="filter.advertiserName"
                  placeholder="Please select Advertiser"
                  class="w100"
                >
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.advertiser"
                    :key="item.advertiserName"
                    :value="item.advertiserName"
                    :label="item.advertiserName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="2" :xl="2">
              <el-form-item label-width="0px">
                <el-button type="primary" size="mini" @click="getList('filter')">Search</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          class="w100"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="Task ID" prop="id"></el-table-column>
          <el-table-column label="offer ID" prop="offerId"></el-table-column>
          <el-table-column label="offer Name" prop="offerTitle" min-width="120px"></el-table-column>
          <el-table-column label="OS" prop="os"></el-table-column>
          <el-table-column label="Country" prop="country"></el-table-column>
          <el-table-column label="Price" prop="price"></el-table-column>
          <el-table-column label="点击数" prop="clickCount"></el-table-column>
          <el-table-column label="安装数" prop="installCount"></el-table-column>
          <el-table-column label="补量安装数" prop="supplementCap"></el-table-column>
          <el-table-column label="上游名称" prop="advertiserName"></el-table-column>
          <el-table-column label="OF名称" prop="emuName"></el-table-column>
          <el-table-column label="状态" prop="status">
            <template slot-scope="scope">
              {{ scope.row.status == 1 ? '开启' : '关闭' }}
            </template>
          </el-table-column>
          <el-table-column
            label="添加时间"
            prop="createTime"
            min-width="110px"
            align="center"
          ></el-table-column>
          <el-table-column
            label="更新时间"
            prop="updateTime"
            min-width="110px"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" width="100" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="edit(scope.row)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="del(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
    <el-dialog
      title="编辑任务"
      :visible.sync="diallog.task"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      width="30%"
    >
      <el-form ref="taskEdit" :model="taskEdit" :rules="rules" label-width="80px">
        <el-form-item label="状态">
          <el-select v-model="taskEdit.status" placeholder="Please select status" class="w100">
            <el-option
              v-for="item in options.status"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格" prop="price"
          ><el-input v-model="taskEdit.price" placeholder="请输入价格"
        /></el-form-item>
        <el-form-item label="安装数" prop="installCount">
          <el-input v-model="taskEdit.installCount" placeholder="请输入安装数" />
        </el-form-item>
        <el-form-item label-width="0px" align="right" class="mb0">
          <el-button @click="diallog.task = false" v-loading="loading.btn">取 消</el-button>
          <el-button type="primary" v-loading="loading.btn" @click="taskEditSub('taskEdit')"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import ddjemuTaskCon from '../../../controllers/DDJ/v3/emu/task';
  export default {
    name: 'ddjemuTask',
    ...ddjemuTaskCon,
  };
</script>

<style></style>
